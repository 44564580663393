<template>
  <ActionInput
    v-focus
    v-click-outside="closeSearch"
    name="search"
    class="sf-search-bar__input"
    :value="term"
    aria-label="Search"
    :placeholder="
      deviceSize && deviceSize.isDesktop
        ? $t('Cerca un prodotto, una categoria, un brand...')
        : $t('Cerca un prodotto...')
    "
    v-bind="$attrs"
    v-on="listeners"
    ref="searchInput"
    @input="handleInput"
    @change="
      handleSearchStart(
        $event.target.value
      ) /* https://github.com/vuestorefront/storefront-ui/issues/2453#issuecomment-1160231619 */
    "
    @keyup.tab="hideSearch"
    @keyup.esc="closeSearch"
  >
    <template #searchButton>
      <CustomButton
        class="sf-action-input__button"
        icon="search"
        icon-color="var(--c-white);"
        @click="handleSearchButton(term), setFocus()"
      />
    </template>
  </ActionInput>
</template>

<script>
import ActionInput from '~/components/ActionInput.vue';
import { focus } from '@storefront-ui/vue/src/utilities/directives';
import CustomButton from '~/components/CustomButton.vue';
import { ref, useContext, useRoute, watch } from '@nuxtjs/composition-api';
import { useCategorySearch, useFacet } from '@gemini-vsf/composables';
import { debounce } from 'lodash-es';
import { SearchCategoriesToExclude } from '~/assets/SearchCategoriesToExclude';
import { clickOutside } from '~/composables/directives/click-outside/click-outside-directive';
import { useGtm } from '~/composables';

export default {
  name: 'SearchInput',
  components: { CustomButton, ActionInput },
  directives: {
    focus,
    clickOutside,
  },
  inheritAttrs: false,
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: [Number, String],
      default: '',
    },
    icon: {
      type: Object,
      default: () => ({}),
    },
    isSearchOpen: {
      type: Boolean,
      default: false,
    },
    itemsPerPage: {
      type: Number,
      default: 12,
    },
    minTermLen: {
      type: Number,
      default: 3,
    },
    categoryFilter: {
      type: Array,
      default: () => [],
    },
    currentPage: {
      type: Number,
      default: 1,
    },
  },
  emits: [
    'set-is-open',
    'set-search-results',
    'set-category-results',
    'loading-category-results',
    'loading-search-results',
  ],
  setup(props, { emit }) {
    const route = useRoute();
    const term = ref('');
    const { app } = useContext();
    const { searchGtmPush } = useGtm();
    let endReached = false;

    const {
      result: searchResult,
      search: productsSearch,
      // loading: productsLoading,
    } = useFacet('AppHeader:Products');
    const { result: categories, search: categoriesSearch } = useCategorySearch(
      'AppHeader:Categories'
    );

    const showSearch = () => {
      if (!props.isSearchOpen) {
        emit('set-is-open', true);
        if (document) {
          document.body.classList.add('no-scroll');
        }
      }
    };

    const hideSearch = () => {
      if (props.isSearchOpen) {
        emit('set-is-open', false);
        emit('set-search-results', null);
        if (document) {
          document.body.classList.remove('no-scroll');
        }
      }
    };

    const toggleSearch = () => {
      if (props.isSearchOpen) {
        hideSearch();
      } else {
        showSearch();
      }
    };

    const filters = {
      cats_ids: [],
    };

    const resetSearch = () => {
      endReached = false;
      term.value = '';
      filters.cats_ids = [];
      emit('resetSearch');
    };

    const closeSearch = (event) => {
      if (document) {
        const searchResultsEl = document.querySelector('.search');
        const closeTriggerElement = event.target;

        if (!searchResultsEl?.contains(closeTriggerElement)) {
          hideSearch();
          resetSearch();
        }
      } else {
        hideSearch();
        resetSearch();
      }
    };

    const searchResults = ref([]);
    const aggregations = ref([]);

    const rawHandleSearch = async (searchTerm) => {
      const itemsPerPage = 40;
      term.value = searchTerm;
      if (term.value.length < props.minTermLen) return;

      filters.cats_ids = [];
      props.categoryFilter.forEach((cat) => {
        filters.cats_ids.push(cat);
      });

      if (filters.cats_ids.length === 0) {
        filters.cats_ids = undefined;
      }

      emit('loading-search-results', true);
      searchGtmPush(term.value);
      await productsSearch({
        page: props.currentPage,
        itemsPerPage,
        term: term.value,
        customQuery: {
          products: 'productListCustom',
        },
        filters,
      });

      aggregations.value = searchResult.value?.data?.availableFilters;
      const catsIds = aggregations.value?.find((aggr) => {
        return aggr?.attribute_code === 'cats_ids';
      });

      const populatedCatsIds = catsIds?.options
        .filter((catId) => {
          return !SearchCategoriesToExclude.has(catId.value.split('::').pop());
        })
        .map((catDataPopulated) => {
          return catDataPopulated?.value.split('::').pop();
        });

      const catIdsCount = {};

      catsIds?.options.forEach((catDataPopulated) => {
        catIdsCount[catDataPopulated?.value.split('::').pop()] =
          catDataPopulated.count;
      });

      emit('loading-category-results', true);

      await categoriesSearch({
        filters: { category_uid: { in: populatedCatsIds } },
      });
      categories.value.sort((a, b) => {
        if (
          catIdsCount?.[a.uid.split('::').pop()] >
          catIdsCount?.[b.uid.split('::').pop()]
        ) {
          return -1;
        }
        if (
          catIdsCount?.[a.uid.split('::').pop()] <
          catIdsCount?.[b.uid.split('::').pop()]
        ) {
          return 1;
        }
        return 0;
      });

      searchResults.value = searchResult.value?.data?.items;

      emit('loading-search-results', false);
      emit('loading-category-results', false);
      emit('set-search-results', searchResults.value);
      emit('set-category-results', categories.value);
    };

    const debouncedHandleSearch = debounce(rawHandleSearch, 1000);

    const handleSearchStart = (searchTerm) => {
      showSearch();

      // cancel debounce timeout started by typing into the searchbar - this is to avoid making two network requests instead of one
      debouncedHandleSearch.cancel();
      rawHandleSearch(searchTerm);
    };

    const handleSearchButton = (val) => {
      if (!endReached) {
        handleSearchStart(val);
      }
    };

    const handleInput = (val) => {
      term.value = val;
    };

    const handleNextPage = async (searchTerm) => {
      // load data for the next page, add it to the existing search results
      // merge aggregations

      const itemsPerPage = 40;
      term.value = searchTerm;
      if (term.value.length < props.minTermLen) return;

      filters.cats_ids = [];
      props.categoryFilter.forEach((cat) => {
        filters.cats_ids.push(cat);
      });

      if (filters.cats_ids.length === 0) {
        filters.cats_ids = undefined;
      }

      emit('loading-search-results', true);
      searchGtmPush(term.value);
      await productsSearch({
        page: props.currentPage,
        itemsPerPage,
        term: term.value,
        customQuery: {
          products: 'productListCustom',
        },
        filters,
      });

      if (
        searchResult.value?.data?.items.length === 0 ||
        !searchResult.value?.data?.items ||
        searchResult.value?.data?.items.length < itemsPerPage
      ) {
        endReached = true;
      }

      aggregations.value = [
        ...aggregations.value,
        // eslint-disable-next-line no-unsafe-optional-chaining
        searchResult.value?.data?.availableFilters,
      ];
      const catsIds = aggregations.value?.find((aggr) => {
        return aggr?.attribute_code === 'cats_ids';
      });

      const populatedCatsIds = catsIds?.options
        .filter((catId) => {
          return !SearchCategoriesToExclude.has(catId.value.split('::').pop());
        })
        .map((catDataPopulated) => {
          return catDataPopulated?.value.split('::').pop();
        });

      const catIdsCount = {};

      catsIds?.options.forEach((catDataPopulated) => {
        catIdsCount[catDataPopulated?.value.split('::').pop()] =
          catDataPopulated.count;
      });

      emit('loading-category-results', true);

      await categoriesSearch({
        filters: { category_uid: { in: populatedCatsIds } },
      });

      categories.value.sort((a, b) => {
        if (
          catIdsCount?.[a.uid.split('::').pop()] >
          catIdsCount?.[b.uid.split('::').pop()]
        ) {
          return -1;
        }
        if (
          catIdsCount?.[a.uid.split('::').pop()] <
          catIdsCount?.[b.uid.split('::').pop()]
        ) {
          return 1;
        }
        return 0;
      });

      searchResults.value = [
        ...searchResults.value,
        // eslint-disable-next-line no-unsafe-optional-chaining
        ...searchResult.value?.data?.items,
      ];

      emit('loading-search-results', false);
      emit('loading-category-results', false);
      emit('set-search-results', searchResults.value);
      emit('set-category-results', categories.value);
    };

    watch(
      () => props.currentPage,
      () => {
        if (
          term.value.length >= props.minTermLen &&
          props.currentPage > 1 &&
          !endReached
        ) {
          handleNextPage(term.value);
        }
      }
    );

    watch(route, () => {
      hideSearch();
      resetSearch();
    });

    // watch categoryFilter to update search results
    watch(
      () => props.categoryFilter,
      () => {
        if (term.value.length >= props.minTermLen && !endReached) {
          handleSearchStart(term.value);
        }
      }
    );

    return {
      term,
      rawHandleSearch,
      debouncedHandleSearch,
      handleSearchStart,
      closeSearch,
      showSearch,
      hideSearch,
      toggleSearch,
      handleSearchButton,
      handleInput,
      deviceSize: app.$device,
    };
  },
  computed: {
    listeners() {
      return {
        // eslint-disable-next-line vue/no-deprecated-dollar-listeners-api
        ...this.$listeners,
      };
    },
  },
  methods: {
    setFocus() {
      // focus needs to be on input to close search with keyup.esc event
      const inputEl =
        this.$refs.searchInput.$el.children[0].children[0].children[0];
      inputEl.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: $tablet-max) {
  .sf-search-bar__input {
    background-color: var(--c-white);

    .sf-action-input__input {
      .sf-input__wrapper {
        #search {
          font-size: var(--font-size--4xs);
        }

        & > input:focus-visible {
          outline: none;
        }
      }
    }
  }
}

@media only screen and (max-width: $tablet-max) and (-webkit-min-device-pixel-ratio: 2) {
  .sf-search-bar__input {
    .custom-button {
      position: fixed;
      height: inherit;
      right: 10px;
    }
  }
}
</style>
