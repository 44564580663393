

























































import { defineComponent, PropType, ref } from '@nuxtjs/composition-api';
import { SfAccordion, SfIcon, SfList } from '@storefront-ui/vue';
import { Menu } from '@gemini-vsf/api-client';
import { useUiHelpers, useUiState } from '~/composables';
// import type { ComponentTemplateRef } from '~/types/componentTemplateRef';
import { preventBodyScroll } from '~/helpers/preventBodyScroll';
import { HamburgerWhite } from '~/static/icons';
import SfAccordionItemCustom from '../../SfAccordionItemCustom.vue';

export default defineComponent({
  name: 'HeaderNavigationMobile',
  components: {
    SfAccordionItemCustom,
    SfList,
    SfIcon,
    SfAccordion,
    HamburgerWhite,
  },
  props: {
    menu: {
      type: Object as PropType<Menu>,
      default: () => ({
        items: [],
      }),
    },
  },
  setup() {
    const { getCatLink } = useUiHelpers();
    const { isMobileMenuOpen, toggleMobileMenu } = useUiState();

    const currentMenuItem = ref(null);
    const lvl0ItemRefs = ref();
    const hasFocus = ref(false);
    let lvl0ItemFocusIdx = 0;
    let focusedElement: HTMLElement | null = null;

    const openMenuLink = () => {
      preventBodyScroll('removeClass', 'open_menu');
      toggleMobileMenu();
    };

    const toggleMenu = () => {
      toggleMobileMenu();
      preventBodyScroll(
        isMobileMenuOpen.value ? 'addClass' : 'removeClass',
        'open_menu'
      );
    };

    const setCurrentMenuItem = (menuItem) => {
      currentMenuItem.value = menuItem;
    };

    const hasChildren = (menuItem) => Boolean(menuItem?.children?.length);

    const setFocus = (event: MouseEvent & { target: HTMLElement }) => {
      focusedElement = event.target;
      lvl0ItemFocusIdx = Number(event.target.dataset.index);
      hasFocus.value = true;
    };

    const focusRootElementAndHideSubcategories = () => {
      setCurrentMenuItem(null);
      if (focusedElement !== null) focusedElement.focus();
    };

    const onMouseEnter = (menuItem) => {
      currentMenuItem.value = menuItem;
      focusedElement = null;
      hasFocus.value = false;
    };

    return {
      getCatLink,
      setCurrentMenuItem,
      currentMenuItem,
      hasChildren,
      setFocus,
      focusRootElementAndHideSubcategories,
      lvl0ItemRefs,
      hasFocus,
      onMouseEnter,
      isMobileMenuOpen,
      toggleMobileMenu,
      preventBodyScroll,
      openMenuLink,
      toggleMenu,
    };
  },
});
