<template>
  <div id="cart" class="cart-mini">
    <!-- title must be not empty to display mini cart header -->
    <SfSidebar
      v-e2e="'sidebar-cart'"
      :visible="isCartSidebarOpen"
      title=" "
      @close="toggleCartSidebar"
      position="right"
    >
      <template #circle-icon>
        <div class="close-icon" @click="toggleCartSidebar">
          <svg
            width="14"
            height="14"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 1L17 17" stroke="#171717" stroke-width="2" />
            <path d="M17 1L1 17" stroke="#171717" stroke-width="2" />
          </svg>
        </div>
      </template>
      <transition name="sf-collapse-top" mode="out-in">
        <div class="notifications">
          <SfNotification
            v-if="!isLoaderVisible"
            :visible="visible"
            :message="
              $t('Sei sicuro di voler rimuovere questo articolo dal carrello?')
            "
            type="secondary"
          >
            <template #action>
              <div class="button-wrap">
                <CustomButton
                  icon=""
                  :primary="false"
                  @click="actionRemoveItem(tempProduct)"
                >
                  {{ $t('Si') }}
                </CustomButton>
                <CustomButton icon="" :primary="true" @click="visible = false">
                  {{ $t('Annulla') }}
                </CustomButton>
              </div>
            </template>
            <template #icon>
              <SfIcon class="display-none" />
            </template>
            <template #close>
              <div></div>
            </template>
          </SfNotification>
        </div>
      </transition>
      <SfLoader v-if="isLoaderVisible" :loading="isLoaderVisible">
        <div />
      </SfLoader>
      <template #content-top>
        <SfProperty
          v-if="totalItems"
          class="sf-property--large cart-summary desktop-only"
          :name="$t('Carrello')"
          :value="totalItems"
        />
      </template>
      <transition name="sf-fade" mode="in-out">
        <div v-if="totalItems && products" key="my-cart" class="my-cart">
          <div class="collected-product-list">
            <div
              v-for="product in products"
              :key="getCartItemListKey(product)"
              class="cart-item-list"
              @click:remove="sendToRemove({ product })"
            >
              <div class="cart-item-media">
                <figure>
                  <img
                    :src="
                      cartGetters.getItemImage(product)
                        ? cartGetters.getItemImage(product)
                        : '/assets/product_placeholder.svg'
                    "
                    alt="Image"
                  />
                </figure>
              </div>
              <div class="cart-item-details">
                <div class="cart-item-content">
                  <div class="cart-item-sku">
                    <p>{{ productSKU(product) }}</p>
                  </div>
                  <nuxt-link
                    :to="
                      fixUrlForCurrentLocale(buildProductUrl(product.product))
                    "
                  >
                    <h3 class="cart-item-name">
                      {{ cartGetters.getItemName(product) }}
                    </h3>
                  </nuxt-link>
                  <div
                    v-if="cartGetters.productHasSpecialPrice(product)"
                    class="cart-item-price"
                  >
                    <p class="cart-item-price__special">
                      {{
                        cartGetters.getItemPrice(product).special &&
                        $n(
                          cartGetters.getItemPrice(product).special,
                          'currency'
                        )
                      }}
                    </p>
                    <p class="cart-item-price__old">
                      {{
                        $n(
                          cartGetters.getItemPrice(product).regular,
                          'currency'
                        )
                      }}
                    </p>
                  </div>
                  <div v-else class="cart-item-price">
                    <p class="cart-item-price__special">
                      {{
                        $n(
                          cartGetters.getItemPrice(product).regular,
                          'currency'
                        )
                      }}
                    </p>
                  </div>
                </div>
                <div class="cart-item-button">
                  <div class="sf-collected-product__quantity-wrapper">
                    <SfQuantitySelector
                      :disabled="loading"
                      :qty="cartGetters.getItemQty(product)"
                      class="sf-collected-product__quantity-selector"
                      @input="updateCartItemQty(product, $event)"
                    />
                  </div>
                  <div
                    class="cart-item-remove"
                    @click="sendToRemove({ product })"
                  >
                    <TrashIcon />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else key="empty-cart" class="empty-cart">
          <div class="empty-cart__banner">
            <SfHeading
              :title="$t('Your shopping cart is empty')"
              :level="2"
              class="empty-cart__heading"
              :description="
                $t(
                  'It looks like you haven\'t added any items to the bag yet. Start shopping to fill it out.'
                )
              "
            />
          </div>
        </div>
      </transition>
      <template #content-bottom>
        <transition name="sf-fade">
          <div v-if="totalItems">
            <CustomButton
              icon=""
              :primary="false"
              @click="toggleCartSidebar"
              :grey-hover="true"
            >
              {{ $t('Keep shopping') }}
            </CustomButton>
            <CustomButton
              icon=""
              class="go-to-cart"
              :primary="true"
              @click.native="toggleCartSidebar"
              link="/checkout/user-account"
            >
              {{ $t('checkout') }}
            </CustomButton>
          </div>
          <div v-else>
            <CustomButton
              icon=""
              :primary="false"
              @click.native="toggleCartSidebar"
            >
              {{ $t('Keep shopping') }}
            </CustomButton>
          </div>
        </transition>
      </template>
    </SfSidebar>
  </div>
</template>

<script>
import {
  SfLoader,
  SfNotification,
  SfSidebar,
  SfHeading,
  SfProperty,
  SfQuantitySelector,
  SfIcon,
} from '@storefront-ui/vue';
import {
  computed,
  defineComponent,
  ref,
  useRouter,
  useContext,
  onMounted,
  watch,
} from '@nuxtjs/composition-api';
import {
  useUser,
  cartGetters,
  useExternalCheckout,
  useProduct,
} from '@gemini-vsf/composables';
// eslint-disable-next-line import/no-extraneous-dependencies
import _debounce from 'lodash.debounce';
import {
  useUiState,
  useUiNotification,
  useUrlHelpers,
  useGtm,
  useCart,
} from '~/composables';
import { useI18n } from '~/helpers/hooks/usei18n';
import { useAddToCart } from '~/helpers/cart/addToCart';
import CustomButton from '~/components/CustomButton.vue';
import { clickOutside } from '~/composables/directives/click-outside/click-outside-directive';
import TrashIcon from '~/components/Checkout/TrashIcon.vue';
import { useAdditionalServices } from '~/helpers/cart/additionalServices';
import { useCurrentLocale } from '~/composables/useCurrentLocale';

export default defineComponent({
  name: 'CartSidebar',
  components: {
    SfLoader,
    SfNotification,
    SfSidebar,
    SfIcon,
    SfHeading,
    SfProperty,
    SfQuantitySelector,
    CustomButton,
    TrashIcon,
  },
  directives: { clickOutside },
  setup(props, { emit }) {
    const trans = useI18n();
    const { initializeCheckout } = useExternalCheckout();
    const { isCartSidebarOpen, toggleCartSidebar } = useUiState();
    const router = useRouter();
    const {
      app,
      $vsf: {
        $gemini: {
          config: {
            installo: { installoServicesCollection },
          },
        },
      },
    } = useContext();
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const {
      cart,
      removeItem,
      updateItemQty,
      bulkRemoveItems,
      bulkUpdateItemsQty,
      addNoteOnCart,
      clear,
      load: loadCart,
      loading,
      error: cartError,
    } = useCart();
    const { search: getProductList, products: services } = useProduct(
      'cartServiceProducts'
    );
    const { showCartError } = useAddToCart();
    const { isAuthenticated } = useUser();
    const { send: sendNotification, notifications } = useUiNotification();
    const { actionCartGtmPush, cartGtmPush, pageGtmPush } = useGtm();
    const { handleNotes, updateServices } = useAdditionalServices();

    const cleanedCartItems = ref([]);
    const products = computed(() => cleanedCartItems.value);
    const totals = computed(() => cartGetters.getTotals(cart.value));
    const totalItems = ref();
    const getAttributes = (product) => product.configurable_options || [];
    const visible = ref(false);
    const isLoaderVisible = ref(false);
    const tempProduct = ref();
    const servicesSku = ref([]);
    const notesObject = ref({});
    const { buildProductUrl } = useUrlHelpers();

    const productSKU = ({ product }) => product?.value?.merchant_sku;

    onMounted(async () => {
      /* Services are associated to a specific categories, ad fetched as products of that category */
      await getProductList({
        filter: {
          category_uid: {
            eq: installoServicesCollection,
          },
        },
        customQuery: {
          productDetail: 'productListServices',
        },
      });
      if (services.value?.items?.length > 0) {
        services.value.items.forEach((product) => {
          servicesSku.value.push(product.sku);
        });
      }
    });

    watch(cart, (newValue, oldValue) => {
      if (cart.value && cart.value?.items.length > 0) {
        cleanedCartItems.value = cart.value.items.filter((cartItem) => {
          return !servicesSku.value.includes(cartItem.product.sku);
        });
        let serviceCounter = 0;
        cart.value?.items?.forEach((cartItem) => {
          if (
            !!cartItem.product.categories &&
            cartItem.product.categories?.length > 0 &&
            cartItem.product.categories?.find(
              (c) => c.uid === installoServicesCollection
            )
          ) {
            serviceCounter += cartItem.quantity;
          }
          totalItems.value = cart.value.total_quantity - serviceCounter;
        });
      }
      if (
        cart.value &&
        cart.value?.notes &&
        cart.value?.notes !== '' &&
        cart.value?.notes !== '{}'
      ) {
        notesObject.value = JSON.parse(cart.value.notes);
      }
      if (
        (!newValue && oldValue) ||
        (cart.value && cart.value?.items.length === 0)
      ) {
        totalItems.value = null;
      }
    });

    watch(isCartSidebarOpen, () => {
      if (isCartSidebarOpen.value) {
        cartGtmPush();
        pageGtmPush('Cart');
      }
    });

    const goToCheckout = async () => {
      const redirectUrl = await initializeCheckout({
        baseUrl: '/checkout/user-account',
      });
      await router.push(`${app.localePath(redirectUrl)}`);
    };

    const sendToRemove = ({ product }) => {
      if (notifications.value.length > 0) {
        notifications.value[0].dismiss();
      }

      visible.value = true;
      tempProduct.value = product;
    };

    const actionRemoveItem = async (product) => {
      isLoaderVisible.value = true;
      await loadCart();
      if (
        cart.value &&
        cart.value?.notes &&
        cart.value?.notes !== '' &&
        cart.value?.notes !== '{}'
      ) {
        notesObject.value = JSON.parse(cart.value.notes);
      }
      if (Object.keys(notesObject.value).includes(product.product.sku)) {
        const servicesToRemove = [];
        const servicesToSubtract = [];
        const servicesToSubtractQtyMapping = {};
        cart.value.items.forEach((serviceCartItem) => {
          /* Check if array of product associated services contains this cartItem sku */
          if (
            notesObject.value[product.product.sku].includes(
              serviceCartItem.product.sku
            )
          ) {
            /* If there's more services than products, update quantity */
            if (
              serviceCartItem?.quantity &&
              serviceCartItem.quantity - product.quantity > 0
            ) {
              /* Push cart item in array of products to remove */
              servicesToSubtract.push(serviceCartItem);
              /* Generate mapping => { serviceCartItemUid: newQuantity } */
              servicesToSubtractQtyMapping[serviceCartItem.uid] =
                serviceCartItem.quantity - product.quantity;
            } /* If they are equal, no other products has this service, so it can be removed */ else {
              servicesToRemove.push(serviceCartItem);
            }
          }
        });
        await handleNotes(
          notesObject.value,
          product.product.sku,
          addNoteOnCart
        );
        if (!cartError.value.addNoteOnCart) {
          if (servicesToRemove.length > 0) {
            await bulkRemoveItems({ products: servicesToRemove });
          }
          if (servicesToSubtract.length > 0) {
            await bulkUpdateItemsQty({
              products: servicesToSubtract,
              quantityMap: servicesToSubtractQtyMapping,
              subtract: true,
            });
          }
          if (cartError.value.bulkRemoveItems) {
            showCartError(cartError.value.bulkRemoveItems);
          }
          if (cartError.value.bulkUpdateItemsQty) {
            showCartError(cartError.value.bulkUpdateItemsQty);
          }
        } else {
          const activeServices = [...servicesToSubtract, ...servicesToRemove];
          /* As a safety measure, remove all services if set note fails. */
          try {
            await bulkRemoveItems({
              products: activeServices.filter(Boolean),
            });
            sendNotification({
              id: Symbol('cartError'),
              type: 'info',
              icon: 'error',
              title: `${trans.t('Error')}`,
              message: `${trans.t(
                "Cart Error: We're sorry, but associated services for all products had to be removed, please try again."
              )}`,
            });
          } catch {
            /* If bulk remove fails too, clear cart as last resort. */
            await clear();
            sendNotification({
              id: Symbol('cartError'),
              type: 'info',
              icon: 'error',
              title: `${trans.t('Error')}`,
              message: `${trans.t(
                "Cart Fatal Error: We're sorry, but your cart has been emptied. Please try again."
              )}`,
            });
          }
        }
      }

      if (
        !cartError.value.bulkRemoveItems &&
        !cartError.value.bulkUpdateItemsQty &&
        !cartError.value.addNoteOnCart
      ) {
        await removeItem({ product });
        if (!cartError.value.removeItem) {
          sendNotification({
            id: Symbol('product_removed'),
            message: `${cartGetters.getItemName(
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              product
            )} ${trans.t('successfully removed from your cart')}`,
            type: 'success',
            icon: 'check',
            persist: false,
            title: 'Product removed',
          });
          actionCartGtmPush(
            product.product,
            product.quantity,
            'remove_from_cart',
            null,
            product.prices
          );
        } else {
          sendNotification({
            id: Symbol('cartError'),
            type: 'info',
            icon: 'error',
            title: `${trans.t('Error')}`,
            message: `${trans.t(
              "There's been an error removing this item. Please try again."
            )}`,
          });
        }
      }

      isLoaderVisible.value = false;
      visible.value = false;
    };

    const updateCartItemQty = async (product, quantity) => {
      if (product.quantity !== quantity) {
        await updateItemQty({
          product,
          quantity: Number.parseInt(quantity, 10),
        });
      }
      if (cartError.value.updateItemQty) {
        showCartError(cartError.value.updateItemQty);
      } else {
        sendNotification({
          id: Symbol('product_changed'),
          message: `${cartGetters.getItemName(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            product
          )} , ${trans.t('quantity changed')}`,
          type: 'success',
          icon: 'check',
          persist: false,
          title: 'Product removed',
        });
      }
      if (!cartError.value.updateItemQty) {
        await updateServices(
          cart.value,
          product.product.sku,
          notesObject.value,
          bulkUpdateItemsQty
        );
      }
    };

    const getCartItemListKey = (cartItem) => {
      if (cartItem.configurable_options?.length > 0) {
        const variantKey = cartItem.configurable_options
          .map(
            (option) =>
              `${option.configurable_product_option_uid}-${option.configurable_product_option_value_uid}`
          )
          .join('-');
        return `${cartItem.product?.merchant_sku}-${variantKey}`;
      }

      return `${cartItem.product?.merchant_sku}`;
    };

    const { fixUrlForCurrentLocale } = useCurrentLocale();

    return {
      trans,
      sendToRemove,
      actionRemoveItem,
      loading: computed(() => !!loading.value),
      isAuthenticated,
      products,
      removeItem,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      updateCartItemQty: _debounce(
        (...params) => updateCartItemQty(...params),
        1000
      ),
      isCartSidebarOpen,
      notifications,
      visible,
      isLoaderVisible,
      tempProduct,
      toggleCartSidebar,
      goToCheckout,
      totals,
      totalItems,
      cartGetters,
      getAttributes,
      sendNotification,
      productSKU,
      buildProductUrl,
      getCartItemListKey,
      deviceSize: app.$device,
      fixUrlForCurrentLocale,
    };
  },
});
</script>

<style lang="scss" scoped>
#cart {
  --sidebar-z-index: 9;
  --overlay-z-index: 9;
  font-family: var(--font-family--secondary);
}

.close-icon {
  position: fixed;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
.notifications {
  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: -350px;
  z-index: 99999;
  .sf-notification {
    padding: 20px;
    background-color: var(--c-white);
    font-family: var(--font-family-primary);
    .button-wrap {
      margin-top: 15px;
      display: flex;
      column-gap: 15px;
      justify-content: space-between;
    }
  }
}
.cart-summary {
  margin-top: var(--spacer-xl);
}

.my-cart {
  flex: 1;
  display: flex;
  flex-direction: column;

  &__total-items {
    margin: 0;
  }

  &__total-price {
    --price-font-size: var(--font-size--m);
    --price-font-weight: var(--font-weight--medium);
    margin: 0 0 var(--spacer-base) 0;
  }
}

.empty-cart {
  --heading-description-margin: 0 0 var(--spacer-xl) 0;
  --heading-title-margin: 0 0 var(--spacer-xl) 0;
  --heading-title-color: var(--c-primary);
  --heading-title-font-weight: var(--font-weight--semibold);
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;

  &__banner {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex: 1;
  }

  &__heading {
    padding: 0 var(--spacer-base);
  }

  &__image {
    --image-width: 16rem;
    margin: 0 0 var(--spacer-2xl) 7.5rem;
  }
}

.collected-product-list {
  flex: 1;
}

.collected-product {
  margin: 0 0 var(--spacer-sm) 0;

  &__properties {
    margin: var(--spacer-xs) 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    flex: 2;

    &:first-child {
      margin-bottom: 8px;
    }
  }

  &__actions {
    transition: opacity 150ms ease-in-out;
  }

  &__save,
  &__compare {
    --button-padding: 0;

    &:focus {
      --cp-save-opacity: 1;
      --cp-compare-opacity: 1;
    }
  }

  &__save {
    opacity: var(--cp-save-opacity, 0);
  }

  &__compare {
    opacity: var(--cp-compare-opacity, 0);
  }

  &:hover {
    --cp-save-opacity: 1;
    --cp-compare-opacity: 1;
  }
}

.cart-mini {
  .cart-item-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }

    .cart-item-media {
      flex: 40%;
      max-width: 30%;
      box-shadow: 0 10px 35px var(--c-transparent-white);
      border-radius: 5px;
    }

    .cart-item-details {
      align-content: space-between;
      display: flex;
      flex-wrap: wrap;
      flex: calc(30% - 26px);
      padding-left: 26px;

      > div,
      > h3 {
        width: 100%;
        height: max-content;
      }

      .cart-item-sku p {
        font-size: var(--font-size--base);
        font-weight: 500;
        letter-spacing: 0;
        line-height: var(--font-size--xs);
        text-transform: uppercase;
      }

      .cart-item-name {
        font-size: var(--font-size--2xs);
        font-weight: bold;
        letter-spacing: 0;
        line-height: var(--font-size--s);
        font-family: var(--font-family--secondary-bold);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .cart-item-price {
        font-size: var(--font-size--xs);
        font-family: var(--font-family--secondary-bold);
        color: var(--c-primary);

        > p {
          display: inline-block;
          font-weight: bold;
          font-size: var(--font-size--xs);
        }

        .cart-item-price__old,
        p:last-child {
          text-decoration-line: line-through;
          color: var(--c-grey-2);
          font-family: var(--font-family--secondary);
        }

        .cart-item-price__special,
        p:first-child {
          padding-top: 15px;
          font-size: var(--font-size--xs);
          text-decoration-line: none;
        }

        .cart-item-price__special:empty {
          color: red;
          padding-right: 0;
        }
      }

      .cart-item-remove {
        z-index: 1;
        margin-left: 20px;
        display: flex;
        align-items: center;
        span {
          z-index: 0;
        }
        &:hover {
          cursor: pointer;
        }
      }

      .cart-item-button {
        display: flex;
        width: 89px;
      }
    }
  }
}

@media (min-width: $desktop-min) {
  #cart {
    & > * {
      --sidebar-bottom-padding: var(--spacer-base);
      --sidebar-content-padding: var(--spacer-base);
    }
  }
  .empty-cart {
    --heading-title-font-size: var(--font-size--m);
    --heading-title-margin: 0 0 var(--spacer-sm) 0;
  }
  .collected-product {
    &:hover {
      .collected-product__properties {
        display: none;
      }
    }
  }
  .cart-mini {
    .cart-item-list {
      .cart-item-media {
        padding: 15px;
        min-height: 160px;
      }
    }
  }
}

@media (max-width: $tablet-max) {
  .close-icon {
    display: none;
  }

  .cart-mini {
    .cart-item-list {
      .cart-item-details {
        padding-left: 19px;

        .cart-item-sku p {
          font-size: var(--font-size--base);
          line-height: var(--font-size--3xs);
        }

        .cart-item-name {
          font-size: var(--font-size--3xs);
          line-height: var(--font-size--s);
        }

        .cart-item-price > p {
          font-size: var(--font-size--base);
        }

        .cart-item-button {
          max-width: 300px;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.cart-mini {
  position: relative;
  z-index: 100;
}

.cart-mini .sf-header__logo {
  display: flex;
}

.cart-mini .sf-image--wrapper {
  width: 100%;
}

.cart-mini .sf-image--wrapper.empty-cart__image {
  margin: 0;
}

.cart-mini .cart-item-media img {
  height: 100%;
  object-fit: contain;
  width: 100%;
}

.cart-mini p {
  margin: 0;
}

.cart-mini figure {
  display: flex;
  margin: 0;
  height: 100%;
  width: 100%;
}

.cart-mini .sf-collected-product__actions {
  display: none;
}

.cart-mini .sf-sidebar__bottom {
  margin: 0 auto 80px auto;
  width: 380px;
  button,
  a {
    width: 100%;
    margin-bottom: 10px;
  }
  .go-to-cart {
    width: unset;
    &:hover {
      background: var(--c-grey-8);
      border: none;
    }
  }
}

.cart-mini .sf-sidebar__top {
  padding: 60px 65px 0;
}

.cart-mini .cart-summary {
  border-bottom: 1px solid #c6c8c6;
  padding-bottom: 20px;
  margin: 0 0 24px;
}

.cart-mini .sf-collected-product {
  display: flex;
  flex-wrap: nowrap;
  padding: 0;
  margin-bottom: 24px;
}

.cart-mini .sf-collected-product__quantity-wrapper {
  position: relative;
  padding: 0;
  flex: calc(100% - 65px);
}
.sf-quantity-selector__button {
  z-index: 1;
}

.sf-collected-product__quantity-selector.sf-quantity-selector {
  background: var(--c-grey-10) 0% 0% no-repeat padding-box;
  border-radius: 32px;
}

.sf-quantity-selector__input {
  border: none !important;
  box-shadow: none !important;
}
.sf-input__wrapper {
  input {
    left: 0 !important;
  }
}

.cart-mini .cart-item-remove {
  flex: 65px;
}

.cart-mini .sf-collected-product__quantity-selector button {
  font-weight: 300;
  flex: 35%;
  font-size: var(--font-size--2xs);
  line-height: var(--font-size--ml);
}

.cart-mini .sf-collected-product__quantity-selector .sf-input {
  flex: 30%;
  box-shadow: none;
}

.cart-mini .sf-icon {
  margin: auto;
}

.cart-mini .sf-input {
  width: 45px;
  --input-height: 45px;
  padding: 0 5px;
}

.cart-mini .sf-sidebar__top .sf-property__name:after {
  content: '';
}

.cart-mini .sf-sidebar__top .sf-property__value:before {
  content: '(';
}

.cart-mini .sf-sidebar__top .sf-property__value:after {
  content: ')';
}

.cart-mini .sf-sidebar__top .sf-property__name,
.cart-mini .sf-sidebar__top .sf-property__value {
  font-size: var(--font-size--sm);
  letter-spacing: -0.9px;
  line-height: var(--font-size--2xl);
  font-weight: 600;
}

.cart-mini .sf-sidebar__bottom .sf-button.color-secondary,
.cart-mini .sf-sidebar__bottom .sf-button.color-primary {
  border: 1px solid #707070;
  font-size: var(--font-size--5xs);
  font-weight: 700;
  height: 65px;
  letter-spacing: 1px;
  line-height: var(--font-size--4xl);
  padding: 0;
}

.cart-mini ~ .notifications .sf-notification__message {
  color: var(--c-white);
  padding: 0 30px 0 10px;
}

.cart-mini .notifications .sf-button {
  padding: 0 30px;
  height: 50px;
  line-height: var(--font-size--xl);
}

.cart-mini .sf-sidebar__bottom .sf-button.color-secondary:hover,
.cart-mini .sf-sidebar__bottom .sf-button.color-secondary:active,
.cart-mini .sf-sidebar__bottom .sf-button.color-secondary:focus,
.cart-mini .sf-sidebar__bottom .sf-button.color-primary:hover,
.cart-mini .sf-sidebar__bottom .sf-button.color-primary:active,
.cart-mini .sf-sidebar__bottom .sf-button.color-primary:focus,
.cart-mini .notifications .sf-button:hover,
.cart-mini .notifications .sf-button:active,
.cart-mini .notifications .sf-button:focus {
  text-decoration-line: none;
}

.cart-mini .cart-item-list .sf-property {
  margin-bottom: 5px;
  padding-right: 15px;
  display: inline-block;
}

.cart-mini .cart-item-list .sf-property:last-child {
  margin-bottom: 5px;
}

.cart-mini .cart-item-list .sf-property__name,
.cart-mini .cart-item-list .sf-property__value {
  font-size: var(--font-size--5xs);
  font-weight: 500;
  letter-spacing: 0;
}

.cart-mini .cart-item-list .sf-property__name {
  margin-right: -2px;
}

.cart-mini .sf-input input {
  font-size: var(--font-size--3xs);
  font-weight: 500;
  padding: 5px 0 0;
}

.cart-mini .sf-input__error-message {
  display: block;
  left: -72px;
  min-width: 210px;
  position: absolute;
  width: 100%;
}

.cart-mini .sf-input input:focus-visible {
  outline: 0;
  border: 0;
}

.cart-mini .empty-cart__heading .sf-heading__title.h2 {
  font-size: var(--font-size--sm);
  text-align: center;
}

.cart-mini .empty-cart__heading .sf-heading__description {
  font-size: var(--font-size--3xs);
  text-align: center;
}

.cart-mini .notifications .sf-icon {
  margin-right: 20px;
}

.cart-mini .notifications .sf-icon svg {
  --icon-color: #212a24;
}

.cart-mini .sf-notification__message,
.cart-mini .sf-notification__title {
  color: #212a24;
}

.cart-mini .sf-sidebar__aside {
  height: 100%;
  z-index: 9;
}

.sf-badge--number {
  background-color: #fdcfc1;
  height: 17px;
  width: 17px;
  font-size: var(--font-size--base);
  color: #222a24;
  font-weight: 700;
  line-height: var(--font-size--xs);
  position: absolute;
  right: -8px;
  top: -5px;
}

.cart-mini .button-go-to-cart {
  margin-top: 15px;
}

@media (min-width: $desktop-min) {
  .cart-mini .sf-sidebar__content {
    padding: 0 65px;
  }

  .cart-mini .sf-sidebar__aside {
    width: 570px;
  }

  .cart-mini .sf-collected-product {
    max-width: 100%;
  }

  .cart-mini .sf-collected-product__configuration {
    justify-content: flex-start;
    padding-bottom: 50px;
  }

  .cart-mini .close-icon {
    right: 65px;
    top: 80px;
  }

  .cart-mini .header-mini-cart {
    display: none;
  }

  .cart-mini .sf-sidebar__bottom .sf-button.color-primary {
    max-width: 439px;
  }
}

@media (max-width: $tablet-max) {
  .cart-mini .sf-sidebar__content,
  .cart-mini .sf-sidebar__top,
  .cart-mini .sf-sidebar__bottom {
    width: 90%;
  }

  .cart-mini .sf-sidebar__bottom {
    margin-bottom: 0;
  }

  .cart-mini .sf-sidebar__content {
    margin: 0 auto;
    padding: 0;
    overflow-x: hidden;
  }

  .cart-mini .cart-item-content div {
    height: max-content;
    line-height: 0;
  }

  .cart-mini .cart-item-list .sf-property__value {
    font-weight: 500;
  }

  .cart-mini .sf-collected-product__quantity-selector {
    height: 40px;
  }

  .cart-mini .sf-collected-product__quantity-selector button {
    font-size: var(--font-size--xs);
    flex: 30%;
    padding: 6px 0 0;
  }

  .cart-mini .sf-collected-product__quantity-selector .sf-input {
    flex: 40%;
  }

  .cart-mini .sf-sidebar__top .cart-summary.desktop-only {
    display: block !important;
  }

  .cart-mini .cart-summary {
    border-bottom: 0;
    margin: 0;
    padding-bottom: 0;
  }

  .cart-mini .sf-sidebar__top {
    padding: 0 var(--spacer-sm);
    margin: 0 auto;
  }

  .cart-mini .close-icon {
    display: block;
    top: 20px;
    right: 20px;
  }

  .cart-mini .sf-bar__icon {
    display: none;
  }

  .cart-mini .sf-bar {
    background: var(--c-white);
    border-bottom: 1px solid #e9ecf1;
    height: 54px;
    display: none;
  }

  .cart-mini .cart-item-list .sf-property {
    padding-right: 0;
    width: 100%;
  }

  .cart-mini .cart-item-list .sf-property > span {
    font-size: var(--font-size--base);
    line-height: var(--font-size--4xs);
  }

  .cart-mini .sf-collected-product__quantity-wrapper {
    flex: calc(100% - 45px);
  }

  .cart-mini .cart-item-remove {
    flex: 45px;
  }

  .cart-mini .sf-input input {
    font-size: var(--font-size--4xs);
    height: 45px;
    line-height: var(--font-size--l);
  }

  .cart-mini .sf-sidebar__bottom .sf-button.color-secondary,
  .cart-mini .sf-sidebar__bottom .sf-button.color-primary {
    font-size: var(--font-size--base);
    letter-spacing: 0;
    height: 55px;
    line-height: var(--font-size--2xl);
  }

  .cart-mini .notifications {
    left: 50%;
    margin: 0;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .cart-mini .sf-header__item_right {
    display: none;
  }

  .cart-mini .header-mini-cart {
    left: 0;
    position: absolute;
    top: -14px;
  }

  .cart-mini .header-mini-cart .sf-header-custom_container {
    position: absolute;
    top: -47px;
  }

  .cart-mini #overlay_menu,
  .cart-mini #mega_menu {
    top: 0;
  }
}

@media (max-width: $mobile-max) {
  .cart-mini .header-mini-cart {
    top: -13px;
  }

  .cart-mini .my-cart .cart-item-list {
    flex-wrap: nowrap;
  }

  .cart-mini .cart-item-list .cart-item-media {
    min-width: 139px;
  }

  .cart-mini .sf-sidebar__top .sf-property__name,
  .cart-mini .sf-sidebar__top .sf-property__value {
    font-weight: 500;
  }
}
</style>
